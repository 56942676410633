$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
	//margin-bottom: 15px;

	@media (min-width: $bpSmMin) {
		padding-right: 5px;
		padding-left: 5px;
		//margin-bottom: 10px;
	}
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.col-auto {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: none;
}

.col-1 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}

.col-2 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}

.col-3 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.col-5 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}

.col-6 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}

.col-8 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}

.col-9 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}

.col-11 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}

.col-12 {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.order-first {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
}

.order-last {
	-webkit-box-ordinal-group: 14;
	-ms-flex-order: 13;
	order: 13;
}

.order-0 {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
}

.order-1 {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}

.order-2 {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
}

.order-3 {
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
}

.order-4 {
	-webkit-box-ordinal-group: 5;
	-ms-flex-order: 4;
	order: 4;
}

.order-5 {
	-webkit-box-ordinal-group: 6;
	-ms-flex-order: 5;
	order: 5;
}

.order-6 {
	-webkit-box-ordinal-group: 7;
	-ms-flex-order: 6;
	order: 6;
}

.order-7 {
	-webkit-box-ordinal-group: 8;
	-ms-flex-order: 7;
	order: 7;
}

.order-8 {
	-webkit-box-ordinal-group: 9;
	-ms-flex-order: 8;
	order: 8;
}

.order-9 {
	-webkit-box-ordinal-group: 10;
	-ms-flex-order: 9;
	order: 9;
}

.order-10 {
	-webkit-box-ordinal-group: 11;
	-ms-flex-order: 10;
	order: 10;
}

.order-11 {
	-webkit-box-ordinal-group: 12;
	-ms-flex-order: 11;
	order: 11;
}

.order-12 {
	-webkit-box-ordinal-group: 13;
	-ms-flex-order: 12;
	order: 12;
}

.offset-1 {
	margin-left: 8.333333%;
}

.offset-2 {
	margin-left: 16.666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.333333%;
}

.offset-5 {
	margin-left: 41.666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.333333%;
}

.offset-8 {
	margin-left: 66.666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.333333%;
}

.offset-11 {
	margin-left: 91.666667%;
}


@media (min-width: $bpSmMin) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-sm-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-sm-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-sm-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-sm-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-sm-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-sm-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-sm-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-sm-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-sm-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
	.order-sm-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13;
	}
	.order-sm-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.order-sm-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.order-sm-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}
	.order-sm-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
	}
	.order-sm-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4;
	}
	.order-sm-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5;
	}
	.order-sm-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6;
	}
	.order-sm-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7;
	}
	.order-sm-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8;
	}
	.order-sm-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9;
	}
	.order-sm-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10;
	}
	.order-sm-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11;
	}
	.order-sm-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-sm-0 {
		margin-left: 0;
	}
	.offset-sm-1 {
		margin-left: 8.333333%;
	}
	.offset-sm-2 {
		margin-left: 16.666667%;
	}
	.offset-sm-3 {
		margin-left: 25%;
	}
	.offset-sm-4 {
		margin-left: 33.333333%;
	}
	.offset-sm-5 {
		margin-left: 41.666667%;
	}
	.offset-sm-6 {
		margin-left: 50%;
	}
	.offset-sm-7 {
		margin-left: 58.333333%;
	}
	.offset-sm-8 {
		margin-left: 66.666667%;
	}
	.offset-sm-9 {
		margin-left: 75%;
	}
	.offset-sm-10 {
		margin-left: 83.333333%;
	}
	.offset-sm-11 {
		margin-left: 91.666667%;
	}
}

@media (min-width: $bpMdMin) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-md-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-md-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-md-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-md-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-md-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-md-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-md-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-md-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-md-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-md-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-md-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
	.order-md-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13;
	}
	.order-md-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.order-md-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.order-md-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}
	.order-md-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
	}
	.order-md-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4;
	}
	.order-md-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5;
	}
	.order-md-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6;
	}
	.order-md-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7;
	}
	.order-md-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8;
	}
	.order-md-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9;
	}
	.order-md-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10;
	}
	.order-md-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11;
	}
	.order-md-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-md-0 {
		margin-left: 0;
	}
	.offset-md-1 {
		margin-left: 8.333333%;
	}
	.offset-md-2 {
		margin-left: 16.666667%;
	}
	.offset-md-3 {
		margin-left: 25%;
	}
	.offset-md-4 {
		margin-left: 33.333333%;
	}
	.offset-md-5 {
		margin-left: 41.666667%;
	}
	.offset-md-6 {
		margin-left: 50%;
	}
	.offset-md-7 {
		margin-left: 58.333333%;
	}
	.offset-md-8 {
		margin-left: 66.666667%;
	}
	.offset-md-9 {
		margin-left: 75%;
	}
	.offset-md-10 {
		margin-left: 83.333333%;
	}
	.offset-md-11 {
		margin-left: 91.666667%;
	}
}

@media (min-width: $bpDtMin) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-lg-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-lg-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-lg-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-lg-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-lg-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-lg-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-lg-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-lg-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-lg-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-lg-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-lg-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
	.order-lg-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13;
	}
	.order-lg-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.order-lg-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.order-lg-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}
	.order-lg-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
	}
	.order-lg-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4;
	}
	.order-lg-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5;
	}
	.order-lg-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6;
	}
	.order-lg-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7;
	}
	.order-lg-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8;
	}
	.order-lg-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9;
	}
	.order-lg-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10;
	}
	.order-lg-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11;
	}
	.order-lg-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-lg-0 {
		margin-left: 0;
	}
	.offset-lg-1 {
		margin-left: 8.333333%;
	}
	.offset-lg-2 {
		margin-left: 16.666667%;
	}
	.offset-lg-3 {
		margin-left: 25%;
	}
	.offset-lg-4 {
		margin-left: 33.333333%;
	}
	.offset-lg-5 {
		margin-left: 41.666667%;
	}
	.offset-lg-6 {
		margin-left: 50%;
	}
	.offset-lg-7 {
		margin-left: 58.333333%;
	}
	.offset-lg-8 {
		margin-left: 66.666667%;
	}
	.offset-lg-9 {
		margin-left: 75%;
	}
	.offset-lg-10 {
		margin-left: 83.333333%;
	}
	.offset-lg-11 {
		margin-left: 91.666667%;
	}
}

@media (min-width: $bpDtLMin) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xl-auto {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: none;
	}
	.col-xl-1 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-xl-2 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-xl-3 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-5 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-xl-6 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-xl-8 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-xl-9 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-xl-11 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-xl-12 {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.order-xl-first {
		-webkit-box-ordinal-group: 0;
		-ms-flex-order: -1;
		order: -1;
	}
	.order-xl-last {
		-webkit-box-ordinal-group: 14;
		-ms-flex-order: 13;
		order: 13;
	}
	.order-xl-0 {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.order-xl-1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.order-xl-2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2;
	}
	.order-xl-3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3;
	}
	.order-xl-4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4;
	}
	.order-xl-5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5;
	}
	.order-xl-6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6;
	}
	.order-xl-7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7;
	}
	.order-xl-8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8;
	}
	.order-xl-9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9;
	}
	.order-xl-10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10;
	}
	.order-xl-11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11;
	}
	.order-xl-12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12;
	}
	.offset-xl-0 {
		margin-left: 0;
	}
	.offset-xl-1 {
		margin-left: 8.333333%;
	}
	.offset-xl-2 {
		margin-left: 16.666667%;
	}
	.offset-xl-3 {
		margin-left: 25%;
	}
	.offset-xl-4 {
		margin-left: 33.333333%;
	}
	.offset-xl-5 {
		margin-left: 41.666667%;
	}
	.offset-xl-6 {
		margin-left: 50%;
	}
	.offset-xl-7 {
		margin-left: 58.333333%;
	}
	.offset-xl-8 {
		margin-left: 66.666667%;
	}
	.offset-xl-9 {
		margin-left: 75%;
	}
	.offset-xl-10 {
		margin-left: 83.333333%;
	}
	.offset-xl-11 {
		margin-left: 91.666667%;
	}
}
