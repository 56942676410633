$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
.row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;

	@media (min-width: $bpSmMin) {
		margin-right: -5px;
		margin-left: -5px;
	}
}

.flex-row {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}

.flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.flex-row-reverse {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: reverse !important;
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: reverse !important;
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}

.justify-content-start {
	-webkit-box-pack: start !important;
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between {
	-webkit-box-pack: justify !important;
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.align-items-start {
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.align-items-end {
	-webkit-box-align: end !important;
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.align-items-center {
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	align-items: center !important;
}