$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: .3s;

.twoStepsWrapper {
    box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);
}

.firstStepWrapper {

    position: relative;
    height: 100px;
    cursor: pointer;

    border-bottom: 1px solid #1BB3BE;
    padding: 40px 25px 15px;
    outline: none !important;

    @media (min-width: $bpMdMin) {
        min-height: 90px;
        padding: 45px 20px 20px;
    }

    @media (min-width: $bpDtMin) {
        padding: 45px 30px 20px;
    }

	&:focus {
		background-color: $primarySkyLight;
	}

}

.secondStepWrapper {
    position: relative;
    height: 100px;
    cursor: pointer;

    padding: 40px 25px 15px;
    outline: none !important;

    @media (min-width: $bpMdMin) {
        min-height: 90px;
        padding: 45px 20px 20px;
    }

    @media (min-width: $bpDtMin) {
        padding: 45px 30px 20px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 53px;
        width: 13px;
        height: 13px;
        display: block;
        background-color: rgba(255, 255, 255, 1);
        border-top: 1px solid $primarySkyDark;
        border-left: 1px solid $primarySkyDark;

        -webkit-transform: rotate(45deg) scale(0);
        -moz-transform: rotate(45deg) scale(0);
        -o-transform: rotate(45deg) scale(0);
        -ms-transform: rotate(45deg) scale(0);
        transform: rotate(45deg) scale(0);

        transition: all $transitionTime ease-in-out;
    }

    &.active{
        &:before{
            -webkit-transform: rotate(45deg) scale(1);
            -moz-transform: rotate(45deg) scale(1);
            -o-transform: rotate(45deg) scale(1);
            -ms-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
        }
    }

	&:focus {
		background-color: $primarySkyLight;
	}

}

.numberContainer {

    display: flex;

    span {
        width: auto;
        height: 25px;
        opacity: 0.7;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #000000;
        margin-right: 8px;
        margin-top: 8px;
        padding: 0px 1px;

	    @media (min-width: $bpXsMax) {
		    font-size: 20px;
		    margin-right: 15px;
	    }

        &.active {
            opacity: 1;
            font-weight: 600;
            border-bottom: 2px solid $primarySkyDark;
        }

    }

}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}
    


// Css hack of the form scss on the radio button
.formItem {
    position: relative;
    min-height: 60px;
    margin-bottom: 5px;
    background-color: $primaryWhite;
    transition: all $transitionTime ease-in-out;
    border-radius: 6px;
    border: 1px solid $primarySkyDark;
    max-height: 100px;
    overflow: hidden;

    &.isOpen {
        max-height: 203px;
    }

    @media (min-width: $bpXsMax) {
        margin-bottom: 10px;
    }

    @media (min-width: $bpMdMin) {
        //padding: 20px 46px 22px;
        border-width: 1px;
        margin: -3px 0 10px 0;
    }

    &:hover {
        border-color: $primarySkyDark;
        border-bottom: 1px solid $primarySkyDark;
        z-index: 1;
        transition: all $transitionTime ease-in-out;
    }

    &.itemDisabled {
        cursor: default;
    }

}

.formItemActive {

    border-color: $secondaryTurquoise;

    .formLabel {
        color: $secondaryTurquoise;
    }

}

.formItemError {

    .radioElem,
    .borderRight {
        border-color: $primaryRed;
    }

    .inputWrapper {

        &:before {
            border-color: $primaryRed;
        }

    }

}
