$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
.pageContainer {
	padding-bottom: 30px;
}

.headerSpacer {
	width: 100%;
	height: 30px;

	@media (min-width: $bpSmMax) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $bpMdMin) and (max-height: 699px) {
		height: 50px;
	}

	@media (min-width: $bpSmMax) and (min-height: 700px) {
		height: 50px;
	}

	@media (min-width: $bpMdMin) and (min-height: 700px) {
		height: 80px;
	}

}

.buttonRow {
	text-align: right;
	button {
		@media (min-width: $bpXsMax) {
			margin-left: 10px;
		}
	}
}

.customButtonsRow {
	justify-content: space-between;
	
	.mandatoryFieldsWrapper {
		font-size: 14px;
		color:$primaryGrey;

		@media (max-width: $bpXsMax) {
			padding-bottom: 24px;
			border-bottom: 1px solid $primarySkyMedium;
		}
	}

	.mandatoryFieldsCol {
		@media (max-width: $bpXsMax) { 
			margin-bottom:12px;
		}
	}
}
.divider {
	width: 100%;
	height: 1px;
	margin-bottom: 20px;
	margin-top: 25px;
	background-color: $primarySkyMedium;
	display: none;

	@media (min-width: $bpXsMax) {
		display: block;
	}
}
.pageTitleRow {
	position: relative;
}
.tooltipIconWrapper {
	position: absolute;
	top:0;
	right:0;
}
.tooltipContentWrapper {
	padding:12px;
	.tooltipText {
		font-size: 0.8rem;
		color:$primaryGrey; 
	}
}
.loadingSpinner {

	text-align: center;
	margin-top: 30px;

	.loadingSpinnerInner {
		width: 50px;
		height: 50px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 50%;
		box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);

		svg {
			border-radius: 50%;
			animation-name: spin;
			animation-duration: 2000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}

	}

}

@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}
