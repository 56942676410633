$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: 0.2s;

.button {
	position: relative;
	width: 100%;
	height: auto;
	padding-left: 0;
	padding-right: 0;
	margin-top: 10px;
	border-radius: 3px;
	border: none;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	transition: all $transitionTime ease-in-out;
	font-weight: bold;

	&:not(.link) {
		min-width: 150px;
		padding-left: 25px;
		padding-right: 25px;
		font-size: 12px;
		letter-spacing: 1.2px;
		text-transform: uppercase;
		font-weight: bold;
		height: 50px;
	}

	&:disabled {
		cursor: not-allowed;
		> .buttonText {
			opacity: 0.5;
		}
	}

	@media (min-width: $bpXsMax) {
		margin-top: 5px;
		width: auto;
	}

	.iconWrapper {

		position: absolute;
		top: 50%;
		transform: translate(0, -50%);

		&.prependIconWrapper {
			left: 25px;
		}

		&.appendIconWrapper {
			right: 25px;
		}

		&.prependIcon {
			padding-left: 75px;
		}

		&.appendIcon {
			padding-right: 75px;
		}

		svg {
			width: 20px;
			height: 16px;
		}

	}

	&.primary {

		background-color: $secondaryPurple;

		> .buttonText {
			color: $primaryWhite;
		}

		&:focus {
			background-color: $secondaryPurpleLight;
		}

		&:hover {
			background-color: $secondaryPurpleDark;
		}

		&:disabled {
			background-color: $secondaryPurple;
			&:hover {
				background-color: $secondaryPurple;
			}
		}

	}

	&.secondary {

		background-color: $primarySkySpecial;

		> .buttonText {
			color: #63818e;
		}

		&:focus {
			background-color: $primarySkyShadow;
		}

		&:hover {
			background-color: $primarySkyMedium;
		}

		&:disabled {
			background-color: $primarySkySpecial;
			&:hover {
				background-color: $primarySkySpecial;
			}
		}

	}

	&.link {

		font-size: 14px;
		line-height: 1.0;
		background-color: transparent;

		&.prependIcon {
			padding-left: 25px;
			text-align: left;
		}

		&.appendIcon {
			padding-right: 25px;
			text-align: right;
		}

		.iconWrapper {

			&.prependIconWrapper {
				left: 0;
			}

			&.appendIconWrapper {
				right: 0;
			}

		}

		> .buttonText {
			color: $secondaryPurple;
		}

		&:focus {

			> .buttonText {
				color: $secondaryPurpleLight;
			}

			svg {
				fill: $secondaryPurpleLight;
				* {
					fill: $secondaryPurpleLight;
				}
			}

		}

		&:hover {

			> .buttonText {
				color: $secondaryPurpleDark;
			}

			svg {
				fill: $secondaryPurpleDark;
				* {
					fill: $secondaryPurpleDark;
				}
			}

		}

	}

}

.spinner {
	text-align: center;
}

.spinner > div {
	width: 10px;
	height: 10px;
	background-color: rgba(255, 255, 255, 0.85);

	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0)
	}
	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}
