$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: .3s;
$checkboxSizeMobile: 25px;
$checkboxSizeDesktop: 30px;

.checkboxWrapper {
	outline: none;

	&:not(.formCheckboxError):focus {
		.inputElem + label:before {
			border-color: $secondaryTurquoise !important;
		}

		.inputElem:checked + label:before {
			border-color: #38CBD6 !important;
			background-color: #38CBD6 !important;
		}
	}

}

.wrapper {
	margin: auto;
	margin-top: 15px;
}

/* Checkboxes styles */
.inputElem {
	display: none;
}

.inputElem ~ label {
	-webkit-tap-highlight-color: transparent;
	display: block;
	position: relative;
	padding-left: $checkboxSizeMobile + 15px;
	padding-right: 50px;
	margin-bottom: 20px;
	font: 12px/16px 'Open Sans', Arial, sans-serif;
	color: rgba(0, 0, 0, 1);
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;

	@media (min-width: $bpXsMax) {
		padding-left: $checkboxSizeDesktop + 15px;
		font: 14px/20px 'Open Sans', Arial, sans-serif;
	}
}

.inputElem + label:last-child {
	margin-bottom: 0;
}



.inputElem + label:before {
	content: '';
	display: block;
	width: $checkboxSizeMobile;
	height: $checkboxSizeMobile;
	border-radius: 2px;
	border: 1px solid $primarySkyShadow;
	background-color: rgba(255, 2255, 255, 1);
	position: absolute;
	left: 0;
	top: -5px;
	opacity: .6;
	transition: all $transitionTime ease;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.11);

	@media (min-width: $bpXsMax) {
		width: $checkboxSizeDesktop;
		height: $checkboxSizeDesktop;
	}
}

.checkIconWrapper {
	pointer-events: none;
	width: $checkboxSizeMobile;
	height: $checkboxSizeMobile;
	position: absolute;
	left: 0;
	top: -5px;
	border: 1px solid transparent;
	opacity: 0.00001;
	transition: all $transitionTime ease;

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 50%;
		height: 50%;
	}

	@media (min-width: $bpXsMax) {
		width: $checkboxSizeDesktop;
		height: $checkboxSizeDesktop;
	}
}

.inputElem:checked {

	& + label {

		&:before {
			border-color: $secondaryTurquoise;
			background-color: $secondaryTurquoise;
			box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.11);
			opacity: 1;
		}

		.checkIconWrapper {
			opacity: 1;
		}

	}
}

.subLabel {
	font-size: 12px !important;
	color: #6f7779 !important;
	margin-top: -18px;
}
