$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: 0.2s;

.formItem {
    position: relative;

    min-height: 80px;
    padding: 40px 25px 15px;
    margin-bottom: 10px;
    margin-top: 0px;

    @media (min-width: $bpXsMax) {
        //margin-top: 10px;
        //margin-bottom: 20px;
    }

    @media (min-width: $bpMdMin) {
        min-height: 90px;
        padding: 45px 20px 20px;
    }

    @media (min-width: $bpDtMin) {
        padding: 45px 30px 20px;
    }

    border: 1px solid $primarySkyShadow;

    background-color: $primaryWhite;
    cursor: text;

    box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);

    transition: all $transitionTime ease-in-out;

    &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &:hover,
    &:active,
    &:focus {
        border-color: $secondaryTurquoise;

        .formLabel {
            color: $secondaryTurquoise;
        }

    }

    &.itemDisabled {
        cursor: default;
    }
}

.formItemActive {

    border-color: $secondaryTurquoise;

    .formLabel {
        color: $secondaryTurquoise;
    }

}

.itemHidden {
    display: none;
}

.itemDisabled {

    background-color: $primarySky;
    border: 1px solid $primaryWhite;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus{
        -webkit-box-shadow: 0 0 0 1000px $primarySky inset;

        @media (min-width: $bpMdMin) {
            -webkit-box-shadow: 0 0 0 1000px $extraSkyLight inset;
        }
    }

}

.formLabel {
    position: absolute;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.0;
    letter-spacing: normal;
    pointer-events: none;
    color: $primaryGrey;
    transition: all $transitionTime ease-in-out;

    top: 20px;

    @media (min-width: $bpXsMax) {
        font-size: 14px;
        top: 20px;
    }

}

.formItemError {

    border-color: $primaryRed !important;

    .formLabel {
        color: $primaryRed !important;
    }

}

.formItemValid {

    border-color: $secondaryGreen !important;

    .formLabel {
        color: $secondaryGreen !important;
    }

}

.formCheckboxError {
    input[type="checkbox"] + label:before {
        border-color: $primaryRed !important;
    }
}
