$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
.headerBorder {
	border-top: 5px solid $secondaryPurpleDark;
	border-bottom: 1px solid rgba(222, 237, 242, 1);
	background-color: rgba(255, 255, 255, 1);
}

.headerWrapper {
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;

	@media (min-width: $bpSmMin) {
		height: 95px;
	}
}

.logo {
	width: 40px;
	height: 95px;
	padding-bottom: 6px;
	position: relative;
	display: flex;
	align-items: center;
	flex-shrink: 0;

	svg {
		width: 35px;
		height: 35px;
	}

	&::after {
		content: '';
		width: 1px;
		height: 30px;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		right: 0px;
		top: 33px;
		display: none;
	}

	@media (min-width: $bpSmMin) {

		width: 165px;

		svg {
			width: 148px;
			height: 26px;
		}

		&::after {
			display: block;
		}

	}
}

.headerHeadline {
	flex: 1;
	min-width: 0;
	padding-right: 30px;
}

.headerTitle {
	font-weight: 300;
	padding: 0;
	margin: 0;
	line-height: 1.2;
	font-size: 14px;
	margin-left: 10px;
	width: 100%;

	flex: 1;
	min-width: 0;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width: $bpSmMin) {
		line-height: 1.5;
		font-size: 20px;
		margin-left: 15px;
		display: inline-block;
		padding-top: 7px;

		br {
			display: none;
		}

	}

	@media (min-width: $bpDtMin) {
		font-size: 24px;
		padding-top: 0;
	}

	strong {
		display: inline-block;
		font-weight: 700;
		margin-right: 10px
	}

}

.headerRight {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	flex-shrink: 0;
	max-width: 100%;
}

.headerRightItem {

	padding-left: 15px;
	display: none;

	&:not(:last-child) {
		padding-right: 15px;
		border-right: 1px solid $primaryGreyLight;
	}

	h6 {
		margin: 0;
		font-size: 12px;
		font-weight: normal;
		color: $primaryGrey;
	}

	p {
		margin: 0;
		font-size: 12px;
		font-weight: bold;
	}

	@media (min-width: $bpDtMin) {
		display: block;
	}

}

.headerRightItemIcon {

	$headerIconSize: 24px;

	display: flex;
	flex-direction: row;
	cursor: pointer;

	@media (min-width: $bpDtMin) {
		display: none;
	}

	&Left {
		width: $headerIconSize;
		height: $headerIconSize;

		position: relative;
		transition: 0.15s;

		svg {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

	}

	&Right {
		width: $headerIconSize;
		height: $headerIconSize;

		position: relative;
		transition: 0.15s;

		svg {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

	}

	&Active {
		.headerRightItemIcon {

			&Left {
				transform: rotate(-45deg);
			}

			&Right {
				transform: rotate(180deg);
			}

		}
	}

	&Tooltip {

		background-color: $primaryWhite;
		border: 1px solid $primarySkySpecial;
		border-top: none;
		padding: 10px 20px 20px;

		position: absolute;
		top: 100%;
		right: 0;
		z-index: 2;

		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;

		display: none;

		&Active {
			display: block;
		}

		&Item {
			width: 100%;

			&:not(:last-child) {
				border-bottom: 1px solid $primaryGreyLight;
				padding-bottom: 10px;
				margin-bottom: 10px;
			}

			h6 {
				margin: 0;
				font-size: 12px;
				font-weight: normal;
				color: $primaryGrey;
			}

			p {
				margin: 0;
				font-size: 12px;
				font-weight: bold;
			}

		}

	}

}

.isDesktop {
	display: none;

	@media (min-width: $bpSmMin) {
		display: block;
	}
}

.isMobile {
	display: block;

	@media (min-width: $bpSmMin) {
		display: none;
	}
}
