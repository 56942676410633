$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
.BorrowersTableDesktop {
	margin-bottom:15px;
	.Wrapper {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: none;
	}
	.Header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		font-size: 1rem;
		margin-bottom:10px;
		margin-top:10px;
		font-weight: bold;
		border-top: 1px solid $primarySkyMedium;
		padding-top: 20px;
	}
	.HeaderCol {
		&.LargeCol {
			padding-left: 39px;
		}
		&.SmallCol {
			padding-left: 20px;
			border:none;
		}

	}
	.Row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		width:100%;
		background-color: $primaryWhite;
		border-left: 1px solid $primaryGreyLight;
		border-right: 1px solid $primaryGreyLight;
		border-bottom: 1px solid $primaryGreyLight;
		&:first-child {
			border-top: 1px solid $primaryGreyLight;
		}
		&.ActiveRow {
			border:1px solid $secondaryTurquoise;
			position: relative;
			z-index: 10;
			.LargeCol.FieldName, .customField {
				padding-top:45px;
			}
			.LargeCol.FieldName {
				font-size: 1.2rem;
				border-bottom: none;
				color:$primaryBlack;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				.tooltipWrapper {
					display: block;
				}
				.tooltipIconWrapper{
					opacity: 1;
					visibility: visible;
				}
			}
			.customField {
				&:hover {
					border-color: $primaryGreyLight;
					border-bottom-color: transparent;
					border-top-color: transparent;
				}
				&:last-child {
					border-right-color: transparent;

				}
			}
		}
		&.ExtraRow {
			border-color: $primaryGreyLight;
			border-top:none;
		}
		&.PrevRow {
			// border-bottom: 1px solid transparent;
		}
		&.filledRow {
			.LargeCol.FieldName, .customField {
				padding-top:45px;
			}
			.LargeCol.FieldName {
				font-size: 1.2rem;
				border-bottom: none;
				color:$primaryBlack;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;

				.tooltipWrapper {
					display: block;
				}
				.tooltipIconWrapper{
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	.LargeCol {
		width:298px;
		position: relative;
		@media(max-width: $bpSmMax) {
			width:258px;
		}
		&.FieldName {
			font-size: 0.9rem;
			color: $primaryGrey;
			padding: 28px 39px;
			-webkit-transition: all .2s ease-in-out;
			-moz-transition: all .2s ease-in-out;
			-ms-transition: all .2s ease-in-out;
			-o-transition: all .2s ease-in-out;
			transition: all .2s ease-in-out;
			.tooltipWrapper{
				display: none;
			}
			.tooltipIconWrapper{
				position: absolute;
				top:20px;
				right:20px;
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	.SmallCol {
		-webkit-box-flex:1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex:1;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;

	}

	.customField {
		padding: 24px 30px 20px;
		min-height: 58px;
		-webkit-box-flex:1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex:1;
		[class*=DefaultInput_inputElem] {
			@media(max-width: $bpMdMax) {
				height: 29px;
				font-size: 15px;
			}
		}
	}

	.customDropDown {
		[class*=DropdownInput_valueWrapper] > div {
			min-height: 0;
			border-radius: 0;
			
			margin: 0;
			padding: 48px 20px 20px;
			padding-left: 37px;
			border-color: transparent;

			&:hover,
			&:active,
			&:focus,
			
			&[class*=formItemActive] {
				border-color: $secondaryTurquoise;
				
			}

			&[class*=formItemActive] {
				[class*=DropdownInput_inputElem] {
					opacity: 0;
				}
				[class*=form_formLabel] {
					opacity: 1;
					visibility: visible;
				}
			}
			
		}
		[class*=DropdownInput_listWrapper] {
			top: calc(100% - 48px);
		}
		[class*=DropdownInput_listWrapperInner] {
			padding-left: 38px;
			padding-right: 38px;
		}
		[class*=form_formLabel] {
			opacity: 0;
			visibility: hidden;
		}
		[class*=DropdownInput_dropownWrapper] {
			li span {
				overflow: hidden;
				text-overflow: ellipsis;
				padding-right: 26px;
				white-space: nowrap;
				display: block;
			}
		}
		
		
	}

	

}

.tooltipContentWrapper {
	padding:12px;
	max-width: 210px;
	.tooltipText {
		font-size: 0.8rem;
		color:$primaryGrey; 
	}
}
// Common

.customField {
	margin:0;
	border-radius: 0 !important; 
	border-color: transparent;
	border-left: 1px solid $primaryGreyLight;
	// border-bottom: 1px solid $primaryGreyLight;

}

.AddFieldBtn {
	-webkit-appearance: none;
	appearance: none;
	outline: none;
	margin:0;
	padding:13px;
	background-color: $extraTurquoiseLight;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width:100%;
	font-size: 0.8rem;
	color:$primaryBlack;
	font-weight: bold;
	cursor: pointer;

	span {
		display: inline-block;
		padding-left: 4px;
	}
}

.BorrowersTableMobile {
	display: block;
	width: 100%;

	.Table {
		margin-bottom: 30px;
	}

	.Wrapper {
		border: 1px solid $primaryGreyLight;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: none;
	}
	.Header {
		margin-bottom: 10px;
		margin-top:10px;
		font-weight: bold;
		border-top: 1px solid $primarySkyMedium;
		padding-top: 20px;
	}
	.FieldName {
		margin: 0;
		// border-bottom:1px solid $primaryGreyLight;
		padding:40px 35px 15px;
		border-radius: 0;
		[class*=form_formLabel] {
			font-size: 14px;
		}
		
		&:last-child {
			// border-bottom: none;
		}
	}
	.AddFieldBtn {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}
	.DropdownCol {
		[class*=form_formItem] {
			border-radius:0;
			margin-bottom: 0;
		}
		
	}

	.InputWrapper {
		position: relative;
	}

	.tooltipIconWrapper{
		position: absolute;
		top:12px;
		right:12px;
	}
}