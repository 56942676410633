$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: .3s;

// .radioGroupWrapper {
//     box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);
// }

.inputWrapper {

    position: relative;
    height: 83px;
    cursor: text;

    padding: 40px 15px 15px;
    outline: none !important;

    @media (min-width: $bpMdMin) {
        min-height: 90px;
        padding: 45px 20px 20px;
    }

    @media (min-width: $bpDtMin) {
        padding: 45px 30px 20px;
    }

    @media (min-width: $bpXsMax) {
        height: 210px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -7px;
        left: 53px;
        width: 13px;
        height: 13px;
        display: block;
        background-color: rgba(255, 255, 255, 1);
        border-top: 1px solid $primarySkyDark;
        border-left: 1px solid $primarySkyDark;

        -webkit-transform: rotate(45deg) scale(0);
        -moz-transform: rotate(45deg) scale(0);
        -o-transform: rotate(45deg) scale(0);
        -ms-transform: rotate(45deg) scale(0);
        transform: rotate(45deg) scale(0);

        transition: all $transitionTime ease-in-out;
    }

    &.active{
        &:before{
            -webkit-transform: rotate(45deg) scale(1);
            -moz-transform: rotate(45deg) scale(1);
            -o-transform: rotate(45deg) scale(1);
            -ms-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
        }
    }
}

.inputElem {
	-webkit-tap-highlight-color: transparent;
    position: relative;
    width: 100%;
    border: none;
    background-color: transparent;
    z-index: 1;
    outline: none;
    padding: 0;

    height: 26px;
    font-size: 20px;
    padding-bottom: 3px;
    line-height: 1.0;
    color: $text;
    font-weight: 300;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus{
	-webkit-box-shadow: 0 0 0px 1000px white inset;
	box-shadow: 0 0 0px 1000px white inset;
}

.radioWrapper {
    position: relative;
    cursor: pointer;
    outline: none !important;

    @media (min-width: $bpXsMax) {
		height: 210px;
		display: flex;
    }

	&:focus {
		.radioElem {
			background-color: $primarySkyLight;
		}
	}

}

.radioElem {
    position: relative;
    width: 100%;
    // height: 83px;
    background: $primaryWhite;
	border-radius: 6px;
    border: 1px solid $primaryGreyLight;
	transition: $transitionTime;
	
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);
	box-shadow: 0 12px 14px -13px rgba(0, 0, 0, 0.11);
	margin: 0 0 7px 0;
	padding: 15px 33px 15px 15px;
	color: $primaryGrey;
	font-weight: 300;

	.tooltipIconWrapper {
		position: absolute;
		right:13px;
		top:50%;
		-webkit-transform:translateY(-50%);
		-moz-transform:translateY(-50%);
		-ms-transform:translateY(-50%);
		-o-transform:translateY(-50%);
		transform:translateY(-50%);
	}

	.textWrapper {
		order: 2;
		max-width: 188px;
	}

	.radioBtn {
		order:1
	}
	
	&:last-child {
		margin:0;
	}
	

	&:hover {
        border-color: $secondaryTurquoise;
		z-index: 1;
		-webkit-transition: all $transitionTime ease-in-out;
		-moz-transition: all $transitionTime ease-in-out;
		-ms-transition: all $transitionTime ease-in-out;
		-o-transition: all $transitionTime ease-in-out;
		transition: all $transitionTime ease-in-out;
		color: $primaryBlack;
	}
	
	

    @media (min-width: $bpMdMin) {
		padding: 15px 20px;
		
    }

    @media (min-width: $bpDtMin) {
        padding: 15px 30px;
    }

    @media (min-width: $bpXsMax) {
		height: 210px;
		margin:0 7px 0 0;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;

		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;

		.tooltipIconWrapper {
			top:13px;
			-webkit-transform:translateY(0);
			transform:translateY(0);
		}

		.textWrapper {
			max-width: 119px;
			font-size: 20px;
			order: 1;
		}

		.radioBtn {
			order:2
		}
    }

}

.tooltipContentWrapper {
	padding:12px;
	max-width: 210px;
	.tooltipTitle {
		font-size: 0.8rem;
		color: $secondaryTurquoise;
	}
	.tooltipDescription {
		font-size: 0.8rem;
		color:$primaryGrey; 
	}

}

.radioElementActive {
	border-color: $secondaryTurquoise;
	z-index: 1;
	-webkit-transition: all $transitionTime ease-in-out;
	-moz-transition: all $transitionTime ease-in-out;
	-o-transition: all $transitionTime ease-in-out;
	-ms-transition: all $transitionTime ease-in-out;
	transition: all $transitionTime ease-in-out;
	color:$primaryBlack;
}
.borderRight {
    border-right: 1px solid $primarySkyDark;
}



.radioBtn {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: rgba(242, 248, 250, 1);
    border-radius: 25px;
    margin-right: 20px;
}

.radioBtnInnerBorder {
    position: absolute;
    top: 9px;
    left: 9px;
    width: 32px;
    height: 32px;
    border: 2px solid rgba(156, 196, 211, 0.2);
    border-radius: 16px;
}

.radioBtnInnerCircle {
    position: absolute;
    top: 6px;
    left: 6px;
    width: 38px;
    height: 38px;
    border: 0px solid rgba(156, 196, 211, 0.2);
    background-color: rgba(70, 193, 201, 1);
	border-radius: 24px;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all $transitionTime ease-in-out;
	-moz-transition: all $transitionTime ease-in-out;
	-ms-transition: all $transitionTime ease-in-out;
	-o-transition: all $transitionTime ease-in-out;
    transition: all $transitionTime ease-in-out;

    &.active {
        transform: scale(1);
    }

    svg {
        position: absolute;
        top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		-o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

}


// Css hack of the form scss on the radio button
.formItem {
    position: relative;
    min-height: 60px;
    margin-bottom: 5px;
	background-color: transparent;
	-webkit-transition: all $transitionTime ease-in-out;
	-moz-transition: all $transitionTime ease-in-out;
	-ms-transition: all $transitionTime ease-in-out;
	-o-transition: all $transitionTime ease-in-out;
    transition: all $transitionTime ease-in-out;
    
    overflow: hidden;

    // &.isOpen {
    //     max-height: 210px;
    // }

    @media (min-width: $bpXsMax) {
		margin-bottom: 10px;
		max-height: 210px;
    }

    @media (min-width: $bpMdMin) {
        //padding: 20px 46px 22px;
        border-width: 1px;
        margin: -3px 0 10px 0;
    }

    @media (min-width: $bpXsMax) {
        max-height: 210px;

        &.isOpen {
            max-height: 210px;
        }
    }


    &.itemDisabled {
        cursor: default;
    }

}

.formItemActive {

    border-color: $secondaryTurquoise;

    .formLabel {
        color: $secondaryTurquoise;
    }

}

.formItemError {

    .radioElem,
    .borderRight {
        border-color: $primaryRed;
    }

    .inputWrapper {

        &:before {
            border-color: $primaryRed;
        }

    }

}
