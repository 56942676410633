$doDebug: false;

@mixin bgRandom() {
    //@debug $doDebug;
    @if $doDebug == true {
        $s-min: 20;
        $s-max: 70;
        $l-min: 30;
        $l-max: 90;

        $s-min: 5;
        $s-max: 100;
        $l-min: 10;
        $l-max: 100;
        background: hsl(random(360), $s-min+random($s-max+-$s-min), $l-min+random($l-max+-$l-min));
    }
}

:export {
    doDebug: $doDebug;
}

//@include bgRandom();


$debug: false;

$bpXsMin: 0;
$bpSmMin: 576px;
$bpMdMin: 750px;
$bpDtMin: 1024px;
$bpDtLMin: 1440px;
/*$bpDtLMin: 1423px;*/
$bpDtXLMin: 1920px;

:export {
  bpXsMin: $bpXsMin;
  bpSmMin: $bpSmMin;
  bpMdMin: $bpMdMin;
  bpDtMin: $bpDtMin;
  bpDtLMin: $bpDtLMin;
  bpDtXLMin: $bpDtXLMin;
}

/* Could be not calculated with Max Values, operations not working */
$bpXsMax: 575px;
$bpSmMax: 749px;
$bpMdMax: 1023px;
$bpDtMax: 1439px;
$bpDtLMax: 1919px;

/*https://codepen.io/sambible/post/browser-scrollbar-widths*/
/* to be mentioned especially when you calc width for max-width 1440px
/* but needs to be considered for calc space for scrollable elements */
$scrollbarWidth: 17px;
/* width - margin - scrollbarWidth */
$max1440Width: 1363px;










/* Primary Colors */
$primaryWhite: #ffffff;
$primaryBlack: #000000;

$primaryGrey25: #D1D8D9;
$primaryGrey50: #B0BDC0;
$primaryGrey: #6F7779;
$primaryGreyLight: #D7DEDF;

$primaryRed: #EC0000;
$primaryRed10: #fee5e8;
$primaryRedMedium: #CC0000;
$primaryRedDark: #990000;

$primarySkyLight: #f8fdfe;
$primarySky: #DEEDF2;
$primarySkySpecial: #deedf2;
$primarySkyMedium: #C3DEE7;
$primarySkyDark: #9BC3D3;
$primarySkyShadow: #9BC3D3;


/* Secondary Colors */
$secondaryYellow: #FFCC33;
$secondaryYellowDark: #946F00;

$secondaryGreen: #63BA68;
$secondaryGreenDark: #3A8340;

$secondaryTurquoise: #1BB3BE;
$secondaryTurquoiseDark: #137E84;

$secondaryPurple: #9E3667;
$secondaryPurpleDark: #732645;
$secondaryPurpleLight: #B9638B;

$secondaryBlue: #3366FF;
$secondaryBlueDark: #0032E6;

/* Extra colors not belonging to the primary/secondary colors */
$extraGrey: #D4D6D7;
$extraTurquoise: #1BB3BC;
$extraTurquoiseLight: #D2F0F2;

$extraYellow: #FFEBAD;
$extraOrange: #FFB613;
$extraSky: #F4FBFD;
$extraSkyLight: #F2F8FA;/*40% $primarySky*/

/*F2F8FA*/

/* Color references composed by color palette */
$error:$primaryRedMedium;
$valid:$secondaryGreen;
$weakValid:$extraOrange;

$globalWarningBg:$extraYellow;
$globalInfoBg:$primaryRed10;

$text: $primaryBlack;
$textLabel: $primaryGrey;
$textLabelError: $error;
$textLabelValid: $valid;
$textLink: $secondaryPurple;
$textMessageError: $error;

$numberNeg: $primaryRedMedium;

$lineColor: $primaryBlack;
$lineThinColor: $primarySky;
/*$lineThinColor2: $primaryGrey25;*/

$inputBorder: $extraGrey;
$inputBorderFocus: $extraTurquoise;
$inputBorderError: $error;
$inputBorderValid: $valid;
$inputBorderWeakValid: $weakValid;

$buttonBg: $secondaryPurple;
$buttonBgSecondary: $primarySkySpecial;
$buttonText: $primaryWhite;

$sidebarNaviBg: $extraSkyLight;/*$primarySky;*/

$headerHeightSm: 73px;
$headerHeightMd: 101px;

$fontFamily: 'SantanderText', 'open_sans', sans-serif;
$transitionTime: .2s;

.inputElem {
    position: relative;
    width: 100%;
    border: none;
    background-color: transparent;
    z-index: 1;
    outline: none;

    height: 26px;
    font-size: 20px;
    padding-top: 3px;
    line-height: 1.0;
    color: $text;
    font-weight: 300;

    white-space: nowrap;
}

.dropownWrapperOuter {
	position: relative;
	outline: none;

}

.dropownWrapper {

    position: relative;
    z-index: 2;
    outline: none;

    * {
        cursor: pointer !important;
    }

	.valueWrapper {
		position: relative;
		z-index: 2;
		outline: none;
        display: none;

		* {
			cursor: pointer !important;
		}

        @media (min-width: $bpXsMax) {
            display: block;
        }

	}

    .listWrapper {
	    //display: none;
        position: absolute;
        top: 80px;
        left: 0;
        width: 100%;
        z-index: 2;
        margin-bottom: 30px;

        overflow-x: hidden;
        height: auto;
        max-height: 0px;
        transition: all $transitionTime ease-in-out;
        display: none;

	    &.active {
		    display: block;
			max-height: 250px;
	    }

        @media (min-width: $bpXsMax) {
            display: block;
        }

    }

    .listWrapperInner {
        max-height: 0px;
        overflow-y: auto;
        width: 100%;
        background-color: $primaryWhite;
        border: 1px solid $secondaryTurquoise;
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

	    margin-top: -1px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        display: none;

        @media (min-width: $bpMdMin) {
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (min-width: $bpDtMin) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (min-width: $bpXsMax) {
            max-height: 251px;
            display: block;
        }

	    &.listWrapperInnerError {
		    border-color: $primaryRed !important;
	    }

    }

    ul {

        padding: 0;
        margin: 0;

        li {
            position: relative;
            font-size: 20px;
            font-weight: 300;
            list-style: none;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 1px solid $primarySky;
            transition: all $transitionTime ease-in-out;

            svg {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }

            &:hover {
				color: $secondaryTurquoise;
				
				svg {
					transform: translate3d(5px, -50%, 0);
					circle {
						stroke-dashoffset: 0;
					}
				}
            }

        }

    }

    .mobileDropdownWrapper {
        display: block;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        height: 80px;

	    select {
		    margin-left: -1px;
		    -webkit-tap-highlight-color: transparent;
		    background: transparent !important;
		    padding: 0;
	    }

        @media (min-width: $bpXsMax) {
            display: none;
        }

        .selectCss {
            position: relative;
            left: 0px;
            width: 100%;
            border: none;
            background-color: #fff;
            z-index: 1;
            outline: none;

            height: 26px;
            font-size: 20px;
            padding-top: 0px;
            line-height: 1.0;
            color: $text;
            font-weight: 300;
            margin-bottom: 10px;

            white-space: nowrap;

            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';

            &:hover,
            &:active,
            &:focus {
                border-color: $secondaryTurquoise;

                .formLabel {
                    color: $secondaryTurquoise;
                }

            }

            &.itemDisabled {
                cursor: default;
            }

            &::-ms-expand {
                display: none;
            }
        }

	}

	
}

.noPlaceholder {
	
	.listWrapper {
		top:calc(100% - 40px);
		&.active {
			max-height: 399px;
		}
	}

	.listWrapperInner {
		@media (min-width: $bpXsMax) {
            max-height: 399px;
            display: block;
        }
	}

	ul {
		border-top: 1px solid $primarySky;
	}
}



.dropownInputWrapper {
    display: inline-block;
}

.dropownArrowWrapper {
    display: inline-block;
    margin-left: 10px;
    position: absolute;
    top: 55%;
    right: 30px;
    z-index: 5;

    svg {
        margin-bottom: 3px;
    }

    @media (min-width: $bpXsMax) {
        position: static;
    }
}

.dropdownInputDefaultValue {
    color: $primaryGrey;
}

.listItemActive {
    color: $secondaryTurquoise;
}
